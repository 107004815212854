<template>
  <div id="add-order-detail">
    <el-dialog title="【查看订单】" :close-on-click-modal="false" width="90%" :visible.sync="visible">
      <div class="searchBox" v-if="type == '月'">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
          <el-form-item label="提货时间">
            <el-date-picker
              v-model="takeGoodsTime"
              type="daterange" align="right" unlink-panels range-separator="-"
              size="small" value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getDataList"
              :picker-options="$store.state.common.pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="车牌号">
            <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号"  @keyup.enter.native="getDataList" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList()" type="primary">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableBox">
        <el-table :data="dataList" :header-cell-class-name="cellClass" ref="multipleTable" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight" border size="mini" max-height="65vh" :span-method="arraySpanMethod"  style="width: 100%;">
          <el-table-column label="账单编号" prop="orderCostBill.billNo" width="110" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="提货时间" width="105" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
            </template>
          </el-table-column>
          <el-table-column label="供应商" prop="vehiclePlan.motorcadeName" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType" width="60"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="实际车型" :formatter="dictConvert" width="80" :show-overflow-tooltip="true" prop="orderInfo.vehicleType">
          </el-table-column>
          <el-table-column label="应付车型" width="80" :show-overflow-tooltip="true" prop="vehiclePlan.vehicleTypeName">
          </el-table-column>
          <el-table-column label="车牌"  prop="vehiclePlan.vehiclePlate" width="70" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="运费" prop="costInfo.freightFee"  width="40"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="报关/入仓费" prop="costInfo.entranceFee" width="70"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="高速费" prop="costInfo.highFee" width="50"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="过磅费" prop="costInfo.weighFee" width="50"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="装卸费" prop="costInfo.loadFee"  width="50"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="押夜/超时费" prop="costInfo.overtimeFee"  width="70"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="提货费" prop="costInfo.takeFee"  width="50"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="加点费" prop="costInfo.addFee"  width="50"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="其他费用" prop="costInfo.otherFee" align="center">
          </el-table-column>
          <el-table-column label="费用小计" prop="costInfo.totalMoney" width="60"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="件数" prop="orderGoods.pieceNum"  width="40"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="重量" prop="orderGoods.weight" width="40"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="体积" prop="orderGoods.volume" width="40"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" label="备注"  width="180" prop="costInfo.otherFeeRemark" align="center">
          </el-table-column>
          <el-table-column label="车次号" prop="vehiclePlan.planNo" width="110" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="订单编号" prop="orderInfo.orderNo" width="110" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="客户名称" prop="orderInfo.customerName" width="70" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="客户联系人"  prop="orderInfo.customerContactName" width="70"  :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column label="客户单号"  prop="orderInfo.customerSelfNo" width="100" :show-overflow-tooltip="true">
          </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
          :page-sizes="[20, 50, 100]" :page-size="dataForm.limit" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="submit">确定</el-button> -->
        <el-button @click="visible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        page: 1,
        limit: 20,
        vehiclePlate: '',
        takeGoodsBeginDate: null,
        takeGoodsEndDate: null
      },
      takeGoodsTime: null,
      pageIndex: 1, // 当前页
      totalPage: 0, // 总数
      companyList: [],
      dataList: [],
      getIndex: 1,
      selectListArr: [],
      row: {},
      todo: 'view',
      type: ''
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    cellClass (row) {
      if (row.columnIndex === 0) {
        return 'DisabledSelection'
      }
    },
    /**
     * 点击表格变色end
     */
    init (row, todo) {
      this.todo = todo
      this.visible = true
      this.type = todo
      this.row = JSON.parse(JSON.stringify(row))
      this.getDataList()
    },
    getDataList () {
      this.dataListLoading = true
      let dataForm = JSON.parse(JSON.stringify(this.dataForm))
      let takeGoodsTime = this.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      dataForm.takeGoodsBeginDate = timeParam.takeGoodsBeginDate
      dataForm.takeGoodsEndDate = timeParam.takeGoodsEndDate
      this.$http({
        url: this.$http.adornUrl(`/costInfo/getOrderBillDetail/${this.row.id}`),
        method: 'get',
        params: this.$http.adornParams(dataForm)
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data.list)
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.dataForm.limit = val
      this.dataForm.page = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.dataForm.page = val
      this.getDataList()
    }
  }
}
</script>
<style lang="less">
#add-order-detail {
  .el-dialog {
    margin-top: 0 !important;
    top: 50%;
    transform: translate(0, -50%);
  }
  .el-dialog__header {
    padding: 10px 0px;
  }
  .el-dialog__body {
    padding: 0px 10px;
    .el-table .DisabledSelection .cell .el-checkbox {
      display: none;
      position: relative
    }
    // .el-table .DisabledSelection .cell:before {
    //   content: '选择';
    //   position: absolute;
    // }
  }
  .el-pagination {
    margin-top: 5px;
    span {
      font-size: 12px;
    }
    .el-input__inner {
      height: 22px !important;
    }
  }
  .el-dialog__footer {
    padding: 10px 10px;
    button.el-button {
      height: 26px;
      line-height: 0;
    }
  }
}
</style>
